/**
 * Author: Devon
 *
 * Component: BoxLoader | FullScreenLoader
 * Description:
 * Loader module provides loading components for use in the application.
 * It includes a basic loader and two variations: a centered box loader
 * and a full-screen loader. These components utilize Chakra UI's Spinner
 * for visual indication of loading states.
 */

import type { SystemStyleObject } from '@chakra-ui/react';
import { Box, Spinner } from '@chakra-ui/react';

type LoaderProps = {
  size?: string;
};
const Loader = ({ size = 'xl' }: LoaderProps) => <Spinner size={size} />;

export const BoxLoader = ({ sx, id, ...rest }: LoaderProps & { sx: SystemStyleObject; id?: string }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...sx,
    }}
    id={id}
  >
    <Loader {...rest} />
  </Box>
);

export const FullScreenLoader = (props: LoaderProps) => (
  <BoxLoader
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'blackalpha.600',
    }}
    {...props}
  />
);
