import { isAxiosError } from 'axios';
import deepEqual from 'deep-equal';
import { useMemo, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import type { Response } from 'watchtower-ui/api/api';
import { parseDateString } from 'watchtower-ui/utils/dateUtils';
import type { NoUndefined } from 'watchtower-ui/utils/typeUtils';

type SortDirection = 'ASC' | 'DESC';

type StringKeys<T> = Extract<keyof T, string>;

type SortOptions = {
  property?: string;
  direction?: SortDirection;
  // Flag to treat strings as numerical value => "42" => 42
  useStringValueAs?: 'string' | 'number';
};

export const numberFormat = (num: unknown, asPercentage?: boolean, showPrecise?: boolean): string => {
  if (!Number.isFinite(num)) {
    return '';
  } else if (asPercentage) {
    return `${(100 * (num as number)).toFixed(2)}%`;
  } else if (Math.abs(num as number) < 1e-9) {
    return '0';
  } else if (Math.abs(num as number) >= 1e15) {
    return `${Math.trunc(num as number).toLocaleString()}`;
  } else {
    const x = parseFloat((num as number).toPrecision(3));
    const magnitude = Math.floor(Math.log10(Math.abs(x)) / 3);
    const roundedNumber = parseFloat((x * 10 ** (-3 * (magnitude < 0 && !showPrecise ? 0 : magnitude))).toFixed(2));
    const lookups = showPrecise ? ['n', 'μ', 'm', '', 'K', 'M', 'B', 'T'] : ['', '', '', '', 'K', 'M', 'B', 'T'];
    return `${roundedNumber}${lookups[magnitude + 3]}`;
  }
};

/**
 * Creates a range of numbers from start to end. If end is not provided, it creates a range from 0 to start.
 * @param start start of the range. If end is not provided, it is considered as end and start is considered as 0.
 * @param end end of the range. If not provided, start is considered as end and 0 is considered as start.
 * @returns array of numbers from start to end.
 */
export const range = (start: number, end?: number): number[] => {
  if (end == null) {
    return range(0, start);
  }

  if (end < start) {
    console.warn(`range :: invalid range ${start} to ${end}, end should be greater than start, returning empty array`);
    return [];
  }

  return new Array(end - start).fill(0).map((_, idx) => idx + start);
};

export const scan = <T, U>(arr: T[], fn: (prev: U, cur: T, index: number) => U, initial: U): U[] =>
  // Since we initialize the array to be nonempty and always append to it it is always nonempty.
  arr.reduce((resArr: U[], cur: T, index: number) => [...resArr, fn(resArr.at(-1)!, cur, index)], [initial]).slice(1);

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is standard practice for array generics
export const zip = <T extends any[]>(...arrs: { readonly [I in keyof T]: readonly T[I][] }): T[] =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- Weird typing here
  range(Math.min(...arrs.map((arr) => arr.length))).map((idx) => arrs.map((arr) => arr[idx]) as [...T]);

/** @internal */
export const areDeepEqual = (a: unknown, b: unknown): boolean => deepEqual(a, b, { strict: true });

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is a standard practice for checking for array types.
export const isDerivedValue = <T, Args extends any[]>(action: T | ((...v: Args) => T)): action is (...v: Args) => T =>
  typeof action === 'function';

const useSetIfDifferent = <T,>(setValue: Dispatch<SetStateAction<T>>) =>
  useMemo<Dispatch<SetStateAction<T>>>(
    () => (value: SetStateAction<T>) => {
      setValue((prev) => {
        const newValue = isDerivedValue(value) ? value(prev) : value;
        if (areDeepEqual(prev, newValue)) {
          return prev;
        } else {
          return newValue;
        }
      });
    },
    [setValue],
  );

export const useStateUpToDeepEquality = <T,>(initialValue: T | (() => T)): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState(initialValue);
  const setValueIfDifferent = useSetIfDifferent(setValue);
  return [value, setValueIfDifferent];
};

type ApiResponse =
  | string
  | {
      success?: boolean;
      data?: string | { detail?: string };
      message?: string;
      err?: {
        data?: {
          detail?: string;
        };
      };
    };

const extractMessageFromApiResponse = (
  data: ApiResponse,
  enableDeveloperLogs: boolean,
  error: Error | null,
): string | null => {
  if (typeof data === 'string') {
    return data;
  } else if (data?.success && data?.data && typeof data.data === 'string') {
    return data.data;
  } else if (data?.message) {
    return data.message;
  } else if (data?.data && typeof data.data !== 'string' && data.data.detail) {
    return data.data.detail;
  } else if (data?.err?.data?.detail) {
    return data.err.data.detail;
  } else if (import.meta.env.DEV || enableDeveloperLogs) {
    return error ? JSON.stringify(error, null, 4) : JSON.stringify(data.err, null, 4);
  }
  return null;
};

export const getErrMessage = (
  apiResponse: ApiResponse | ApiResponse[] | null,
  error: Error | null,
  enableDeveloperLogs: boolean,
): string => {
  if (isAxiosError(error) && error.response) {
    const msg = getErrMessage(error.response.data as ApiResponse, null, enableDeveloperLogs);
    return error.response.status >= 500 ? `Server Error (${error.response.status} - ${error.code}): ${msg}` : msg;
  }
  if (apiResponse) {
    const responses = Array.isArray(apiResponse) ? apiResponse : [apiResponse];
    for (const data of responses) {
      const message = extractMessageFromApiResponse(data, enableDeveloperLogs, error);
      if (message) return message;
    }
  }
  return 'There was an error loading the data.';
};

export const pick = <Target extends Record<string, unknown>, KeysToPick extends keyof Target>(
  targetObj: Target,
  keys: readonly KeysToPick[],
): [Pick<Target, KeysToPick>, boolean] => {
  let hasAllTargetKeys = true;
  const target = keys.reduce(
    (acc, key: KeysToPick) => {
      if (Object.hasOwn(targetObj, key)) {
        acc = {
          ...acc,
          [key]: targetObj[key],
        };
      } else {
        hasAllTargetKeys = false;
      }
      return acc;
    },
    {} as Partial<Pick<Target, KeysToPick>>,
  );
  return [target as Pick<Target, KeysToPick>, hasAllTargetKeys];
};

const opIfBothExist = (a: unknown, b: unknown, op: (x: number, y: number) => number | undefined): number | undefined =>
  typeof a === 'number' && typeof b === 'number' ? op(a, b) : undefined;

export const diffIfBothExist = (a: unknown, b: unknown): number | undefined => opIfBothExist(a, b, (x, y) => x - y);

export const filterQueryParams = (searchParams: string | URLSearchParams, keptParams: readonly string[]) => {
  const urlParams = searchParams instanceof URLSearchParams ? searchParams : new URLSearchParams(`${searchParams}`);
  // Iterate over the parameters to remove
  const params = Array.from(urlParams.keys());
  for (const param of params) {
    if (!keptParams.includes(param)) {
      // Remove the query parameter if it's not in the allowed list
      urlParams.delete(param);
    }
  }
  return urlParams.toString();
};

export const isResponseSuccess = <TData,>(data: (Response<TData> | undefined)[]) =>
  data && Array.isArray(data) && data.every((item) => item?.success);
export const isDataResultErrString = <TData,>(data: (Response<TData> | undefined)[]) =>
  data && Array.isArray(data) && data.every((item) => item?.success && typeof item.data !== 'string');
export const isDataErrString = <TData,>(data: (Response<TData> | undefined)[]) =>
  data && Array.isArray(data) && data.every((item) => typeof item !== 'string');

type TypeSortArray = string | number | Record<string, unknown> | undefined;

const getValueByKey = <T, K extends StringKeys<T>>(obj: T, key: K): T[K] => obj[key];

const stringSort = (a: string, b: string, sortType: string) => {
  const numA = parseFloat(a);
  const numB = parseFloat(b);
  // @ts-expect-error: Using coercion on purpose here.
  // eslint-disable-next-line no-restricted-globals -- Using coercion behavior on purpose here.
  if (!Number.isNaN(numA) && !Number.isNaN(numB) && !isNaN(a) && !isNaN(b)) {
    return sortType === 'ASC' ? numA - numB : numB - numA;
  }
  return sortType === 'ASC' ? a.localeCompare(b) : b.localeCompare(a);
};

const numberSort = (a: number, b: number, sortType: string) => (sortType === 'ASC' ? a - b : b - a);

const sortStrAndNumbers = (a: unknown, b: unknown, direction: SortDirection) => {
  if (typeof a === 'number' && typeof b === 'number') return numberSort(a, b, direction);
  if (typeof a === 'string' && typeof b === 'string') return stringSort(a, b, direction);
  return 0;
};

export const localeSortBy = <T extends TypeSortArray>(array: T[], options?: SortOptions): T[] => {
  const { property, direction = 'ASC' } = options ?? {};
  if (array.length === 0 || !Array.isArray(array)) return array;

  return array.toSorted((a: TypeSortArray, b: TypeSortArray) => {
    const labelA = property && a && typeof a === 'object' ? getValueByKey(a, property) : a;
    const labelB = property && b && typeof b === 'object' ? getValueByKey(b, property) : b;
    if (labelA && labelB) {
      return sortStrAndNumbers(labelA, labelB, direction);
    } else if (labelB) {
      return -1;
    } else if (labelA) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const sortDateValues = (a: string, b: string, direction: SortDirection = 'ASC'): number => {
  const dateA = parseDateString(a);
  const dateB = parseDateString(b);
  if (dateA && dateB) {
    return direction === 'ASC' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
  }
  return 0;
};

const sortStringValues = (a: string, b: string, direction: SortDirection): number =>
  direction === 'ASC' ? a.localeCompare(b) : b.localeCompare(a);

const sortNumberValues = (a: number, b: number, direction: SortDirection): number =>
  direction === 'ASC' ? a - b : b - a;

const handleNullOrUndefinedValues = (a: unknown, b: unknown, direction: SortDirection): number | null => {
  if (a == null && b == null) return 0;
  if (a == null) return direction === 'ASC' ? -1 : 1;
  if (b == null) return direction === 'ASC' ? 1 : -1;
  return null;
};

const getValueFromInstance = <T extends TypeSortArray>(item: T, key?: string): unknown => {
  if (key && typeof item === 'object') {
    return item?.[key];
  }
  return item;
};

const compareInstanceValues = (
  valueA: unknown,
  valueB: unknown,
  direction: SortDirection,
  useStringValueAs?: SortOptions['useStringValueAs'],
): number => {
  // Handle null/undefined values
  const nullCheck = handleNullOrUndefinedValues(valueA, valueB, direction);
  if (nullCheck !== null) return nullCheck;

  // Sort strings
  if (typeof valueA === 'string' && typeof valueB === 'string') {
    if (useStringValueAs === 'number') {
      const numA = parseFloat(valueA);
      const numB = parseFloat(valueB);
      // Both values are valid numbers check
      if (!Number.isNaN(numA) && !Number.isNaN(numB)) {
        return sortNumberValues(numA, numB, direction);
      }
    }

    return sortStringValues(valueA, valueB, direction);
  }

  // Sort numbers
  if (typeof valueA === 'number' && typeof valueB === 'number') {
    return sortNumberValues(valueA, valueB, direction);
  }

  // Default compare as strings
  return sortStringValues(String(valueA), String(valueB), direction);
};

export const sortArrayInstance = <T extends TypeSortArray>({
  array,
  options,
}: {
  array: T[];
  options?: SortOptions;
}): T[] => {
  const { property, direction = 'ASC', useStringValueAs } = options ?? {};

  // Basic Type Check
  if (!Array?.isArray(array) || array?.length === 0) return array;

  return Array.from(array)?.sort((a, b) => {
    const compareValueA = getValueFromInstance(a, property);
    const compareValueB = getValueFromInstance(b, property);

    return compareInstanceValues(compareValueA, compareValueB, direction, useStringValueAs);
  });
};

export const markdownToSections = (markdown: string, page: string, path: string) => {
  const sections = [];
  let currentTitle = null;
  let content = '';

  const lines = markdown.split('\n');
  for (const line of lines) {
    const regex = /^#{1,3} (.*)/;
    const match = regex.exec(line); // Regex to capture headings
    if (match) {
      // New section found, push previous section
      if (currentTitle && content) {
        sections.push({ title: currentTitle, content, page, path });
      }
      currentTitle = match[1] ? match[1].trim() : ''; // Update title
      content = ''; // Reset content
    } else {
      content += `${line}\n`; // Append line to content
    }
  }

  // Push the last section if content exists
  if (currentTitle && content) {
    sections.push({ title: currentTitle, content, page, path });
  }

  return sections;
};

const isObject = (obj: unknown) => obj && typeof obj === 'object' && !Array.isArray(obj) && !(obj instanceof Date);

export const findModifiedKeys = (obj1: object, obj2: object) => {
  if (isObject(obj1) && isObject(obj2)) {
    const keys1 = obj1 && Object.keys(obj1);
    const keys2 = obj2 && Object.keys(obj2);
    const allKeys = Array.from(new Set([...keys1, ...keys2]));
    const modifiedKeys = allKeys.filter((key) => {
      const typedKey = key as keyof typeof obj1;
      if (Object.hasOwn(obj1, typedKey) && Object.hasOwn(obj2, typedKey)) {
        if (!areDeepEqual(obj1[typedKey], obj2[typedKey])) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    });
    return modifiedKeys;
  }
  return undefined;
};

export const asArray = <T extends string = string>(val: Record<T, unknown> | T[] | string | null | undefined): T[] => {
  if (!val || typeof val === 'string') return [];
  else if (Array.isArray(val)) return val;
  else return Object.keys(val) as T[];
};

export const removeUndefined = <T extends object>(data: T): NoUndefined<T> =>
  Object.fromEntries(Object.entries(data).filter(([, v]) => typeof v !== 'undefined' && v !== null)) as NoUndefined<T>;

export const objectToQueryParams = (obj: object, prefix = ''): string => {
  const queryString = Object.entries(obj)
    .map(([key, value]) => {
      const encodedKey = encodeURIComponent(prefix ? `${prefix}[${key}]` : key);
      if (Array.isArray(value) && value?.length > 0) {
        return value?.map((item: string | number | boolean) => `${encodedKey}=${encodeURIComponent(item)}`).join('&');
      } else if (typeof value === 'object' && value instanceof Object && value !== null) {
        return objectToQueryParams(value as object, encodedKey);
      } else {
        return `${encodedKey}=${encodeURIComponent(value as string | number | boolean)}`;
      }
    })
    .join('&');
  return queryString;
};

type SumRet<T extends object, K extends keyof T> = number extends T[K] ? number : number | undefined;

export const sumProperty = <T extends object, K extends keyof T>(arr: readonly T[], prop: K): number | SumRet<T, K> => {
  if (arr.length === 0) {
    return 0;
  } else if (arr.some((t) => typeof t[prop] === 'number')) {
    return arr.reduce((prev, t) => {
      const value = t[prop];
      if (typeof value === 'number') {
        return prev + (value as number);
      } else {
        return prev;
      }
    }, 0 as number);
  } else {
    // @ts-expect-error This cannot happen if the type doesn't allow undefined which is the case where this is a disallowed return type.
    return undefined;
  }
};

export const stringToAbsFloat = (str: string | undefined): number => {
  if (str === undefined) {
    return 0;
  }
  const strParsed = str.replace(/[^0-9a-zA-Z.-]+/g, '');
  return Math.abs(parseFloat(strParsed));
};
export const commaNumberFormat = (n: number | string) => {
  const numStr = n.toString();
  const parts = numStr.split('.');
  const integerPart = parts[0];
  const decimalPart = parts[1] ?? '';

  const formattedInteger = integerPart?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  return formattedInteger + (decimalPart ? `.${decimalPart}` : '');
};

export const textToCaptilize = (str: string) =>
  str.length > 0 ? str.charAt(0).toLocaleUpperCase() + str.slice(1) : str;
