/**
 * Author: Devon
 *
 * Component: Unauthorized
 * Description:
 * - Unauthorized access view that informs users they are not authorized and provides an option to log out and retry authentication.
 */

import { useMsal } from '@azure/msal-react';
import { Box, Button, Heading, Image, Text } from '@chakra-ui/react';
import type { FC } from 'react';

import bgLogin from 'watchtower-ui/assets/images/bg_login.png';
import footerLogo from 'watchtower-ui/assets/images/loginFooterLogo.png';
import logo from 'watchtower-ui/assets/images/logoWT.svg';
import yellowBar from 'watchtower-ui/assets/images/yelloBar.svg';
import t from 'watchtower-ui/locales/i18n';

const Unauthorized: FC<object> = () => {
  const msalDetails = useMsal();
  const handleClick = () => {
    // eslint-disable-next-line no-void -- Need to tell it to explicitly ignore the result of this function
    void msalDetails.instance.logout();
  };
  return (
    <Box
      overflow="auto"
      display="flex"
      height="100%"
      width="100%"
      position="fixed"
      alignItems="center"
      justifyContent="center"
      background={`url(${bgLogin})`}
      backgroundAttachment="fixed"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center center"
      padding="30px 20px"
    >
      <Box
        textAlign="center"
        color="white"
        backgroundColor="whitealpha.200"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="gray.500"
        borderRadius="14px"
        padding="16px"
        width="100%"
        maxWidth="320px"
        minHeight="480px"
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Image width="80px" src={logo} alt="LOGO" marginX="auto" />
        <Heading as="h2" size="heading5" fontWeight="600" marginTop="10px">
          {t('common:watchtower')}
        </Heading>
        <Heading as="h1" size="heading3" fontWeight="700" marginTop="40px" marginBottom="0">
          {t('common:requestAccess')}
        </Heading>
        <Text fontFamily="'Open Sans', 'Avant', 'light', sans-serif" marginY="40px" marginX="0">
          {t('common:notAuthorised')}
        </Text>
        <Button variant="outline" colorScheme="yellow" mt={4} onClick={() => handleClick()}>
          {t('common:logoutAndRetry')}
        </Button>
        <Image
          position="absolute"
          bottom="-7px"
          left="50%"
          width="120px"
          transform="translateX(-50%)"
          src={yellowBar}
          alt="yellowBar"
        />
      </Box>
      <Image
        width="100%"
        maxWidth="350px"
        position="fixed"
        bottom="20px"
        left="20px"
        src={footerLogo}
        alt="loginFootLogo"
      />
    </Box>
  );
};
export default Unauthorized;
