/**
 * Author: Gaurav Kumar
 *
 * Module: api.js
 * This module provides utilities for authenticated Http Requests using MSAL and Tanstack React Query
 *
 * Features:
 * - MSAL Integration: Manages authentication tokens via MSAL, including token retrieval and silent refresh.
 * - React Query Integration: Provides hooks (`useAuthenticatedQuery`, `useAuthenticatedQueries`) to manage authenticated API calls.
 * - Session Token Management: Handles session storage for tokens (`AUTH_TOKEN_KEY`, `AUTOMATION_TOKEN`).
 *
 * Hooks:
 * - useIsAuthorized: Runs an effect to check if the user is authorized by retrieving a token from session storage.
 * - useAuthenticatedQuery: A wrapper around `useQuery` for making API calls with authentication, managing dependencies and error handling.
 * - useAuthenticatedQueries: A wrapper around `useQueries` to handle multiple API calls requiring authentication.
 *
 * Utilities:
 * - getAuthToken: Retrieves an authentication token from session storage or calls MSAL to acquire a new token.
 * - retrieveNewAuthToken: Uses MSAL to acquire a new token silently, storing it in session storage.
 * - httpCall: Makes an authenticated API call using Axios, handling msal token.
 */

import type { IMsalContext } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import type { NotifyOnChangeProps, UseQueryResult } from '@tanstack/react-query';
import { useQueries, useQuery } from '@tanstack/react-query';
import axios, { isAxiosError } from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useEffect, useMemo } from 'react';

import { useCustomNavigate } from 'watchtower-ui/components/common/CustomNavigation';
import { FEATURE_FLAG_QUERY_PARAM, useFeatureFlag } from 'watchtower-ui/contexts/FeatureFlagContext';
import { AUTH_TOKEN_KEY } from 'watchtower-ui/utils/Constants';

/** @internal */
export const AUTOMATION_TOKEN = 'Automation_token';

export type Response<T> = { success: true; data: T } | { success: false; err?: AxiosResponse; message?: string };

type NavigateFunction = ReturnType<typeof useCustomNavigate>;

export type AuthResponse = { token_type: string; access_token: string };

const retrieveNewAuthToken = async (
  msalDetails: IMsalContext,
  navigate: NavigateFunction,
  enableDeveloperLogs: boolean,
) => {
  const account = msalDetails.accounts[0];
  if (!account) {
    console.warn('Trying to retrieve a token with no active accounts.');
    return null;
  }
  try {
    const response = await msalDetails.instance.acquireTokenSilent({ scopes: [], account, forceRefresh: true });
    if (response.idToken) {
      sessionStorage.setItem(AUTH_TOKEN_KEY, response.idToken);
      return response.idToken;
    }
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 404) {
      navigate({ to: '/unauthorized', keptQueryParams: [FEATURE_FLAG_QUERY_PARAM] });
    } else if (import.meta.env.DEV || enableDeveloperLogs) {
      console.warn(err);
    }
  }
  return null;
};

const getAuthToken = async (msalDetails: IMsalContext, navigate: NavigateFunction, enableDeveloperLogs: boolean) => {
  const token =
    sessionStorage.getItem(AUTOMATION_TOKEN) ??
    sessionStorage.getItem(AUTH_TOKEN_KEY) ??
    (await retrieveNewAuthToken(msalDetails, navigate, enableDeveloperLogs));
  if (token) {
    return `Bearer ${token}`;
  } else {
    sessionStorage.removeItem(AUTH_TOKEN_KEY);
    return null;
  }
};

export const useIsAuthorized = () => {
  const msalDetails = useMsal();
  const navigate = useCustomNavigate();
  const [enableDeveloperLogs] = useFeatureFlag('DEVELOPER_LOGS');
  useEffect(() => {
    // eslint-disable-next-line no-void -- We just want to run this in the background
    void (async () => {
      await getAuthToken(msalDetails, navigate, enableDeveloperLogs);
    })();
  }, [msalDetails, navigate, enableDeveloperLogs]);
};

const httpCall = async <T>(
  config: AxiosRequestConfig,
  msalDetails: IMsalContext,
  navigate: NavigateFunction,
  enableDeveloperLogs: boolean,
  recursiveCall: boolean = false,
): Promise<Response<T>> => {
  const token = await getAuthToken(msalDetails, navigate, enableDeveloperLogs);
  if (!token) return { success: false };
  const headerConfig: AxiosRequestConfig = {
    ...config,
    headers: {
      ...(config.headers ?? {}),
      Authorization: token,
    },
    validateStatus: config.validateStatus ?? ((status) => status < 400),
  };
  try {
    const response = await axios<T>(headerConfig);
    return {
      success: true,
      data: response.data,
    };
  } catch (err) {
    console.error('got error from request', err, config);
    if (isAxiosError(err)) {
      if (err.response?.status === 401) {
        sessionStorage.removeItem(AUTH_TOKEN_KEY);
        if (recursiveCall) {
          navigate({ to: '/unauthorized', keptQueryParams: [FEATURE_FLAG_QUERY_PARAM] });
        } else {
          return httpCall(config, msalDetails, navigate, enableDeveloperLogs, true);
        }
      }
    }
    throw err;
  }
};

export type AuthQueryOptions = {
  dependencies?: boolean[];
  notifyOnChangeProps?: NotifyOnChangeProps;
};

export const useAuthenticatedQuery = <T>(
  queryKey: unknown[],
  queryConfig: AxiosRequestConfig,
  additionalOptions?: AuthQueryOptions,
  refetchOnWindowFocus?: boolean,
): UseQueryResult<Response<T>> => {
  const msalDetails = useMsal();
  const navigate = useCustomNavigate();
  const [enableDeveloperLogs] = useFeatureFlag('DEVELOPER_LOGS');
  const result = useQuery({
    queryKey,
    queryFn: () => httpCall<T>(queryConfig, msalDetails, navigate, enableDeveloperLogs),
    enabled: (additionalOptions?.dependencies ?? []).every((x) => x),
    notifyOnChangeProps: additionalOptions?.notifyOnChangeProps ?? ['data', 'error'],
    refetchOnWindowFocus: refetchOnWindowFocus ?? false,
    retry: false,
    throwOnError: false,
    retryOnMount: true,
  });
  const { isPending, error, data } = result;
  useEffect(() => {
    if (import.meta.env.DEV || enableDeveloperLogs) {
      if (error) {
        console.error(error);
      } else if (isPending) {
        console.debug(queryKey, 'Pending');
      } else {
        console.debug(queryKey, data);
      }
    }
  }, [enableDeveloperLogs, error, isPending, data, queryKey]);
  return result;
};

type UseAuthenticatedQueries = {
  queryKey: unknown[];
  queryConfig: AxiosRequestConfig;
  additionalOptions?: AuthQueryOptions;
  refetchOnWindowFocus?: boolean;
};

export const useAuthenticatedQueries = <T>(queryList: UseAuthenticatedQueries[]): UseQueryResult<Response<T>>[] => {
  const msalDetails = useMsal();
  const navigate = useCustomNavigate();
  const [enableDeveloperLogs] = useFeatureFlag('DEVELOPER_LOGS');
  const result = useQueries({
    queries: queryList.map(({ queryKey, queryConfig, additionalOptions, refetchOnWindowFocus }) => ({
      queryKey,
      queryFn: () => httpCall<T>(queryConfig, msalDetails, navigate, enableDeveloperLogs),
      enabled: (additionalOptions?.dependencies ?? []).every((x) => x),
      notifyOnChangeProps: additionalOptions?.notifyOnChangeProps ?? ['data', 'error'],
      refetchOnWindowFocus: refetchOnWindowFocus ?? false,
      retry: false,
      throwOnError: true,
      retryOnMount: true,
    })),
  });
  const error = useMemo(
    () =>
      result.reduce((axiosError, { error: axiosEr }) => {
        if (axiosEr) axiosError.push(axiosEr);
        return axiosError;
      }, [] as Error[]),
    [result],
  );
  const newIsPending = useMemo(() => result.map(({ isPending }) => isPending), [result]);
  const newData = useMemo(() => result.map(({ data }) => data), [result]);
  const newQueryKey = useMemo(() => queryList.map(({ queryKey }) => queryKey), [queryList]);
  useEffect(() => {
    if (import.meta.env.DEV || enableDeveloperLogs) {
      if (error.length > 0) {
        console.error(error);
      } else if (newIsPending.length > 0) {
        console.debug(newQueryKey, 'Pending');
      } else {
        console.debug(newQueryKey, newData);
      }
    }
  }, [enableDeveloperLogs, error, newIsPending, newData, newQueryKey]);
  return result;
};

// apis

/** @internal */
export const BASE_URL = import.meta.env.VITE_API_BASE_URL;
/** @internal */
export const LOGIN_URL = '/users/login';
/** @internal */
export const SCENARIO_URL = '/api/scenarios';
/** @internal */
export const HISTORICAL_URL = '/api/historical-decomp/';
/** @internal */
export const GET_USER_DETAILS_URL = 'api/users/info';
/** @internal */
export const GET_DRIVER_DISCRIPTION_URL = '/api/historical-decomp/mapping/driver-desc-url';
/** @internal */
export const GET_HISTORICAL_BRAND_URL = '/api/historical-decomp/homepage/landing-brands';
/** @internal */
export const GET_DRAFT_DATA = '/api/pnl-draft';
/** @internal */
export const POST_DRAFT_DATA = '/api/pnl-draft';
/** @internal */
export const GET_SCENARIO_DATA = '/api/optimizer/scenario';
/** @internal */
export const GET_PARTITION_DATA = '/api/commercial_priority_mapping';
/** @internal */
export const INVESTMENT_CHART = '/api/historical-decomp/insight/inv-ev-chart';
/** @internal */
export const DR_INVESTMENT_CHART = '/api/historical-decomp/insight/delta-inv-eff-chart';
/** @internal */
export const POST_SAVE_SIMULATION_DATA = '/api/save-simulation';
/** @internal */
export const DELETE_BULK_SIMULATION = '/api/delete-simulations';
/** @internal */
export const GET_DRIVERS_DATA = '/api/historical-decomp/mapping/chart-signals';
/** @internal */
export const GET_COLUMN_CHART_DATA = '/api/historical-decomp/insight/clustered-chart';
/** @internal */
export const GET_TOP_BOTTOM_CHART = '/api/historical-decomp/insight/top-bottom-chart';
/** @internal */
export const GET_STACKED_LINE_CHART = '/api/historical-decomp/insight/stacked-line-chart';
/** @internal */
export const GET_INVEST_EFFEC_CHART_DATA = '/api/historical-decomp/insight/invest-efficiency/tabular/revenue';
/** @internal */
export const POST_SAVE_PL_PROTOTYPE = '/api/optimizer/scenario/pnl-opt:testing5/';
/** @internal */
export const GET_DISTRIBUTION_TABLE_DATA = '/api/historical-decomp/distribution/chart';
/** @internal */
export const POST_LONG_TERM_IMPACT = '/api/historical-decomp/long-term-impact';
/** @internal */
export const POST_MOONLIGHT_WALK_THROUGH = '/api/historical-decomp/moonlight-walk-through';
/** @internal */
export const POST_DRIVER_ANALYSIS = '/api/historical-decomp/driver-analysis';
