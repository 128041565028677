import {
  differenceInCalendarWeeks,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isMatch,
  isSameDay,
  isValid,
  parse,
  startOfDay,
  sub,
} from 'date-fns';

const defaultDateFormat = 'yyyy-MM-dd';

export const defaultMonthFormat = 'MMM-yyyy';

export const formatDate = (date: Date, dateFormat: string = defaultDateFormat) => format(date, dateFormat);

export const isSaturday = (date: Date): boolean => date.getDay() === 6;

const convertLocalDateToUTCIgnoringTimezone = (date: Date) =>
  new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
  );

export const parseDateString = (dateString: string, moveToUTC?: boolean): Date | null => {
  let formattedDateString = dateString;

  // Check if dateString is in the format 'MMM-yyyy', and if so, convert it to the last day of the month
  if (isMatch(dateString, defaultMonthFormat)) {
    formattedDateString = formatDate(endOfMonth(parse(dateString, defaultMonthFormat, new Date())));
  }

  const delimiter = dateString.includes('/') ? '/' : '-';
  const [first, second, third] = formattedDateString.split(delimiter).map((s) => parseInt(s, 10));

  let year;
  let month;
  let day;
  if (delimiter === '/') {
    [month, day, year] = [first, second, third];
  } else {
    [year, month, day] = [first, second, third];
  }

  // Check if year, month, and day are valid integers
  if (!Number.isSafeInteger(year) || !Number.isSafeInteger(month) || !Number.isSafeInteger(day)) return null;

  // Ensure year, month, and day are not undefined before creating a new Date
  if (typeof year === 'undefined' || typeof month === 'undefined' || typeof day === 'undefined') return null;

  const date = new Date(year, month - 1, day);
  if (moveToUTC) {
    return convertLocalDateToUTCIgnoringTimezone(date);
  } else {
    return date;
  }
};

export const getFirstWeek = (selectedDate: Date) => {
  // We are copying the date so we can modify it.
  const result = new Date(selectedDate);
  result.setMonth(0, 1);
  result.setDate(7 - result.getDay());
  return result;
};

export const yearBackDay = (endDate: Date) => {
  const date = sub(endDate, { years: 1 });
  const lastDay = date.getDate() - (date.getDay() - 1) + 5;
  return new Date(date.setDate(lastDay));
};

// made the changes as the test should pass in every time zone, so date will be coneverted into UTC and then checked
export const isDateInRange = (date: Date, minDate: Date, maxDate: Date): boolean => {
  const utcDate = startOfDay(date).getTime();
  const utcMinDate = startOfDay(minDate).getTime();
  const utcMaxDate = startOfDay(maxDate).getTime();

  return (
    isSameDay(date, minDate) ||
    isSameDay(date, maxDate) ||
    (isAfter(utcDate, utcMinDate) && isBefore(utcDate, utcMaxDate) && isSaturday(date))
  );
};
export const isValidDate = (date: unknown): date is Date => isValid(date) && date instanceof Date;
export const getPreviousSaturday = (date: Date): Date => {
  const dayOfWeek = date.getDay();
  const daysToSubtract = (dayOfWeek + 1) % 7;
  const previousSaturday = new Date(date);
  previousSaturday.setDate(date.getDate() - daysToSubtract);
  return previousSaturday;
};

export const checkDiffInWeeks = (startDate: Date | undefined, endDate: Date | undefined): number | undefined => {
  if (startDate && endDate) {
    // here 1 we are adding because we are comparing with saturday's so everytime it will one less count
    return differenceInCalendarWeeks(endDate, startDate) + 1;
  }
  return undefined;
};
