import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const initializeI18n = async () => {
  try {
    await i18n
      .use(LngDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: {
          default: ['en'],
        },
        ns: ['common', 'homepage'],
        defaultNS: 'common',
        debug: false,
        resources: {
          en: {
            common: (await import('watchtower-ui/locales/en/common.json')).default,
            homepage: (await import('watchtower-ui/locales/en/homepage.json')).default,
          },
          'en-US': {
            common: (await import('watchtower-ui/locales/en/common.json')).default,
            homepage: (await import('watchtower-ui/locales/en/homepage.json')).default,
          },
          'en-CA': {
            common: (await import('watchtower-ui/locales/en/common.json')).default,
            homepage: (await import('watchtower-ui/locales/en/homepage.json')).default,
          },
        },
        detection: {
          order: ['navigator'],
        },
      });
    console.log('i18n initialized successfully');
  } catch (error) {
    console.error('i18n initialization failed', error);
  }
};

await initializeI18n();

const t = i18n.t.bind(i18n);
export default t;
