/**
 *
 * Author: Devon
 *
 * Component: NavigateLink
 * Description:
 * - Component as a customizable link that integrates with React Router.
 * - modifying query parameters from the current URL while allowing the addition of new parameters.
 *
 * Hooks: UseCustomNavigate
 * Description:
 * - Provides a custom navigation function tailored to retain and modify query parameters.
 */

import type { SystemStyleObject } from '@chakra-ui/react';
import { Link } from '@chakra-ui/react';
import { type PropsWithChildren, useCallback } from 'react';
import type { NavigateOptions } from 'react-router-dom';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { filterQueryParams } from 'watchtower-ui/utils/utilityFunctions';

type NavigationProps = {
  hover?: SystemStyleObject;
  to: string;
  color?: string;
  searchParam?: string;
  extendSearchParam?: boolean;
  keptQueryParams?: readonly string[];
  sx?: SystemStyleObject;
  hash?: string;
};

export const NavigateLink = ({
  hover = {},
  to,
  color,
  searchParam,
  extendSearchParam = false,
  keptQueryParams = [],
  children,
  sx = {},
  hash,
}: NavigationProps & PropsWithChildren) => {
  const { search } = useLocation();
  const existingUrlParams = extendSearchParam ? filterQueryParams(search, keptQueryParams ?? []) : '';
  const urlSearchParams = existingUrlParams + (searchParam ? `&${searchParam}` : '');
  return (
    <Link
      _hover={hover ?? {}}
      as={NavLink}
      sx={sx ?? {}}
      to={{
        pathname: to,
        hash,
        search: urlSearchParams,
      }}
      color={color ?? 'textBlack'}
    >
      {children}
    </Link>
  );
};

type CustomNavigation = {
  to: string;
  options?: Omit<NavigateOptions, 'state'>;
  extendSearchParams?: boolean;
  keptQueryParams?: string[];
  searchParam?: string;
};

export const useCustomNavigate = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  return useCallback(
    ({ to, options, extendSearchParams, keptQueryParams, searchParam }: CustomNavigation) => {
      const existingUrlParams = extendSearchParams ? filterQueryParams(search, keptQueryParams ?? []) : '';
      const urlSearchParams = existingUrlParams + (searchParam ? `&${searchParam}` : '');
      navigate({
        pathname: to,
        ...(options ?? {}),
        search: urlSearchParams,
      });
    },
    [search, navigate],
  );
};
