/**
 * Module: App | Maintainer: Gaurav Kumar
 * Main application component for initializing the React app.
 *
 * This component handles authentication using MSAL (Microsoft Authentication Library)
 * and applies theming through Chakra UI. It conditionally renders either the authenticated
 * content (`AppInner`) or the authentication flow based on the session state.
 *
 * It also integrates external services like Datadog RUM for user monitoring,
 * and sets up React Query's `QueryClient` for API data fetching.
 *
 * Wraps Application theming, authentication, and routing.
 *
 * @returns {JSX.Element} The root application Component
 */

import { Theme } from '@abi-labs-hexa/web-v2/core/theme';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import type { IMsalContext, MsalAuthenticationResult } from '@azure/msal-react';
import { ChakraProvider } from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { type FunctionComponent, lazy, memo, Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import '@abi-labs-hexa/web-v2/core/theme/index.css';

import { AUTOMATION_TOKEN } from 'watchtower-ui/api/api';
import { FullScreenLoader } from 'watchtower-ui/components/common/Spinner';
import router from 'watchtower-ui/routes';
import theme from 'watchtower-ui/theme';

const Unauthorized = lazy(() => import('watchtower-ui/pages/Unauthorized/Unauthorized'));

const queryClient = new QueryClient();

const AppInner = memo(() => (
  <QueryClientProvider client={queryClient}>
    <Suspense fallback={<FullScreenLoader />}>
      <RouterProvider router={router} />
    </Suspense>
  </QueryClientProvider>
));

const MsalApp = memo(() => {
  const isAuthenticated = useIsAuthenticated();
  const {
    inProgress,
    accounts: [activeAccount],
  } = useMsal();
  useEffect(() => {
    if (!import.meta.env.DEV) {
      if (activeAccount) {
        datadogRum.setUser({ email: activeAccount.username, id: activeAccount.username, name: activeAccount.name });
      } else {
        datadogRum.clearUser();
      }
    }
  }, [activeAccount]);
  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{}}
        loadingComponent={FullScreenLoader as unknown as FunctionComponent<IMsalContext>}
        errorComponent={Unauthorized as unknown as FunctionComponent<MsalAuthenticationResult>}
      >
        <AppInner />
      </MsalAuthenticationTemplate>
      {inProgress === InteractionStatus.None && !isAuthenticated && (
        <UnauthenticatedTemplate>
          <Unauthorized />
        </UnauthenticatedTemplate>
      )}
    </>
  );
});

const App = memo(() => (
  <Theme>
    <ChakraProvider theme={theme}>
      {sessionStorage.getItem(AUTOMATION_TOKEN) ? <AppInner /> : <MsalApp />}
      <ToastContainer />
    </ChakraProvider>
  </Theme>
));
App.displayName = 'App';
export default App;
